// Variable overrides
//
// If you want to customize your project please add your variables below.
$font-family-sans-serif:  Montserrat,system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$enable-deprecation-messages: false !default;

//https://colorscheme.ru/color-converter.html
//https://www.colorxs.com/palette/hex/000000-00050c-00193f-002d72-0041a5-0055d8-0c6cff?scheme=monochromatic
$primary-base:  #002d72 !default;
$primary-100:   #e6eaf1 !default;
$primary-200:   #ccd5e3 !default;
$primary-300:   #b3c0d5 !default;
$primary-400:   #99abc7 !default;
$primary-500:   #8096b9 !default;
$primary-600:   #6681aa !default;
$primary-700:   #4d6c9c !default;
$primary-800:   #33578e !default;
$primary-900:   #194280 !default;

$primary:   $primary-base !default;
$success:   #2eb85c !default;
$danger:    #e55353 !default;
$warning:   #f9b115 !default;
$info:      #747af2 !default;

$primary-text-emphasis:   shade-color($primary, 60%) !default;
$success-text-emphasis:   shade-color($success, 60%) !default;
$info-text-emphasis:      shade-color($info, 60%) !default;
$warning-text-emphasis:   shade-color($warning, 60%) !default;
$danger-text-emphasis:    shade-color($danger, 60%) !default;

$primary-bg-subtle:       tint-color($primary, 80%) !default;
$success-bg-subtle:       tint-color($success, 80%) !default;
$info-bg-subtle:          tint-color($info, 80%) !default;
$warning-bg-subtle:       tint-color($warning, 80%) !default;
$danger-bg-subtle:        tint-color($danger, 80%) !default;

$primary-border-subtle:   tint-color($primary, 60%) !default;
$success-border-subtle:   tint-color($success, 60%) !default;
$info-border-subtle:      tint-color($info, 60%) !default;
$warning-border-subtle:   tint-color($warning, 60%) !default;
$danger-border-subtle:    tint-color($danger, 60%) !default;

$primary-gradient: (
  "start":  tint-color($primary, 30%),
  "stop":   shade-color($primary, 10%)
) !default;

$danger-gradient: (
  "start":  tint-color($danger, 30%),
  "stop":   shade-color($danger, 10%)
) !default;

$warning-gradient: (
  "start":  tint-color($warning, 30%),
  "stop":   shade-color($warning, 10%)
) !default;

$success-gradient: (
  "start":  tint-color($success, 30%),
  "stop":   shade-color($success, 10%)
) !default;

$info-gradient: (
  "start":  tint-color($info, 30%),
  "stop":   shade-color($info, 10%)
) !default;

$font-size-base:            .9375rem !default;
$border-color-translucent:  rgba(#080a0c, .0875) !default;
$footer-bg:                 var(--cui-body-bg) !default;
$table-cell-padding-y:      .75rem !default;
$table-cell-padding-x:      .75rem !default;

$sidebar-bg:   $primary;

$input-disabled-bg: $primary-100;

$k-border-radius: 1.5rem;

$nav-tabs-border-width: 0;
$nav-tabs-border-radius: 1rem;
$nav-tabs-link-active-color: white;
$nav-tabs-link-active-bg: $primary-800
